<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Berikut adalah <b>Batch Stok Obat</b> yang ada di {{clinic_name}}
      </div>
      <!-- <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/medicine/maintain-stock-add')"
        >Tambah</b-button
      > -->
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <!-- <div class="row justify-content-end mt-n3">
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.medicine_name"
                    placeholder="Cari Nama Obat"
                    @keyup="filterByName"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div> -->

            <!-- Table -->
            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              :style="'white-space: nowrap'"
              responsive="sm"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '20%' : '' }"
                />
              </template>
              <template #cell(actions)="data">
                <!-- <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="$router.push({ path: '/medicine/maintain-stock-detail/' + data.item.id, })"
                ><i class="fas fa-eye px-0"></i></b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  v-if="manipulateBtn == true"
                  @click="$router.push({path: '/medicine/maintain-stock-edit/' + data.item.id,})"
                ><i class="fas fa-edit px-0"></i></b-button>
                <b-button
                  size="sm"
                  class="mr-1"
                  variant="primary"
                  v-b-tooltip.hover
                  title="Lihat Batch"
                  @click="$router.push({path: '/medicine/maintain-stock-edit/' + data.item.id,})"
                ><i class="fas fa-boxes px-0"></i></b-button> -->
                <b-button
                  size="sm"
                  class="mr-1"
                  variant="primary"
                  v-b-tooltip.hover
                  title="Riwayat Batch"
                  @click="showModal(data.item.id)"
                  ><i class="fas fa-history px-0"></i
                ></b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>

            <!-- Modal Batch History -->
            <b-modal
              ref="my-modal"
              hide-footer
              title="Riwayat Medicine"
              size="xl"
            >
              <b-table
                striped
                hover
                class="mt-3"
                :items="items_history"
                :fields="fields_history"
                :style="'white-space: nowrap'"
                responsive="sm"
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'actions' ? '20%' : '' }"
                  />
                </template>
                <template #cell(stock_in_out)="data">
                  <i
                    v-if="data.item.stock_in_out == 0"
                    class="fas fa-arrow-down"
                    style="color: green"
                    v-b-tooltip.hover
                    title="Stok Masuk"
                  ></i>
                  <i
                    v-if="data.item.stock_in_out == 1"
                    class="fas fa-arrow-up"
                    style="color: red"
                    v-b-tooltip.hover
                    title="Stok Keluar"
                  ></i>
                </template>
                <!-- <template #cell(actions)="data">
                  <b-button
                    size="sm"
                    class="mr-1"
                    variant="primary"
                    v-b-tooltip.hover
                    title="Riwayat Batch"
                    @click="
                      $router.push({
                        path: '/medicine/maintain-stock-edit/' + data.item.id,
                      })
                    "
                    ><i class="fas fa-history px-0"></i
                  ></b-button>
                </template> -->
              </b-table>
              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </b-modal>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
// import ApiService from "@/core/services/api.service"

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        medicine_name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "medicine_name",
          label: "Obat",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "batch_no",
          label: "Batch No",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "expired",
          label: "Kadaluarsa",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "first_quantity",
          label: "Stok Awal",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "saldo",
          label: "Sisa Stok",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "warehouse_name",
          label: "Gudang",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Head
      items: [],
      manipulateBtn: false,
      items_history: [],
      fields_history: [
        {
          key: "number",
          label: "#",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "stock_in_out",
          label: "Jenis Stok",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "medicine_name",
          label: "Obat",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "batch_no",
          label: "Batch No",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "quantity",
          label: "Kuantitas",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "remaining_stock",
          label: "Sisa Stok",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        // { key: "actions", label: "Aksi" },
      ],
      clinic_name : window.configuration == null ? "Medhop" :  window.configuration.clinic_name
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let response = await module.paginate(
        "medicine-batch-stocks/get-by-medicine-stock/" + this.$route.params.id
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },

    async paginationHistory(id) {
      let response = await module.paginate(
        "medicine-batch-histories/get-by-medicine-batch-stock-id/" + id
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items_history = response.data;
    },

    filterByName() {
      this.pagination();
    },

    showModal(id) {
      this.$refs["my-modal"].show();
      this.paginationHistory(id);
    },

    async deleteData(id, medicine_id, vendor_id) {
      // Delete Data
      let result = await module.delete(
        "medicine-stocks/" + id + "/" + medicine_id + "/" + vendor_id
      );
      if (result) {
        console.log(result, medicine_id, vendor_id);
        this.pagination();
      }
    },

    async getMedicineHistory() {
      let history = await module.get("medicine-stocks-history/" + 2);
      console.log("history", history);
      // If Data Not Found
      // if (this.history == null) {
      //   // Redirect To List
      //   this.$router.push('/medicine/maintain-stock')
      // }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        ;

        if (access_right[a] == "9005") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Obat", route: "/medicine/list" },
      { title: "Stok Obat", route: "/medicine/maintain-stock" },
      { title: "Batch Stok Obat" },
    ]);
    this.pagination();
    this.getMedicineHistory();
    this.setActiveMenu();
  },
};
</script>

<style scoped>
.th-list-medicines {
  vertical-align: middle !important;
}

.td-list-medicines {
  vertical-align: middle !important;
}
</style>